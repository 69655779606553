import React, { useEffect, useState } from "react";

import Card from "components/Card/Card.js";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import CardBody from "components/Card/CardBody.js";
import OpenBackdrop from "components/BackDrop/BackDrop.js";

import { useForm } from "react-hook-form";
import CensoSheet from "components/Censo/CensoSheet.js";
import RegularButton from "components/CustomButtons/Button.js";
import { Icon, TextField } from "@material-ui/core";

import DropDownMenu from "components/DropDownMenu/DropDownMenu";
import CustomCardHeader from "components/ViewCardHeader/CustomCardHeader";
import CreateCenso from "components/Censo/CreateCenso";
import AlertCustom from "components/Alerts/AlertCustom";

import sheet from "../../assets/sheets/modelo-carga-censo.xlsx";
import { getAllQuestionaires } from "api/questionnaireApi";
import { getQuestionnaireById } from "api/questionnaireApi";
import { uploadSheet } from "api/questionnaireApi";
import { useLoginContext } from "context/LoginContext";
import { downloadSheetById } from "api/questionnaireApi";
import { saveAs } from "file-saver";
import { deleteQuestionnaire } from "api/questionnaireApi";
import DeleteQuestionnaireDialog from "components/DeleteDialog/DeleteQuestionnaireDialog";

export default function CensoCarga() {
  const [chechedQuestionnaire, setCheckedQuestionnaire] = useState(false);
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [message, setMessage] = useState("");
  const [questionnaires, setQuestionnaires] = useState([]);
  const [questionnaireId, setQuestionnaireID] = useState("");
  const [questionnaire, setQuestionnaire] = useState("");
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const { handleSubmit } = useForm();
  const [file, setFile] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const { isReader } = useLoginContext();

  useEffect(() => {
    const fetchQuestionnaire = async () => {
      const data = await getQuestionnaireById(questionnaireId);
      setQuestionnaire(data);
    };
    fetchQuestionnaire();
  }, [questionnaireId]);

  const handleCheckedQuestionnaire = () => {
    setCheckedQuestionnaire(!chechedQuestionnaire);
  };

  useEffect(() => {
    const fetchQuestionnaires = async () => {
      const data = await getAllQuestionaires();
      setQuestionnaires(data);
    };
    fetchQuestionnaires();
  }, []);

  useEffect(() => {
    const fetchQuestionnaires = async () => {
      if (refresh) {
        const data = await getAllQuestionaires();
        setQuestionnaires(data);
      }
    };
    fetchQuestionnaires();
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  const onSubmit = handleSubmit(async (data) => {
    setMessage(
      "Carga iniciada com sucesso! Aguarde alguns minutos e confira os dados atuais."
    );
    setSeverity("success");
    setOpen(true);
    setRefresh(true);
    setFile(null);
    await uploadSheet(file, questionnaireId);
  });

  const downloadSheet = () => {
    const link = document.createElement("a");
    link.href = sheet;
    link.download = "modelo-carga-censo.xlsx";
    link.click();
  };

  const handleDownloadAtualSheet = async () => {
    const response = await downloadSheetById({
      questionnaire_id: questionnaireId,
    });

    const downloadFile = (response) => {
      const blob = new Blob([response.data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `dados.xlsx`);
    };
    downloadFile(response);
  };

  const handleDeleteQuestionnaire = async (id) => {
    await deleteQuestionnaire({
      questionnaire_id: id,
    });

    setMessage("Questionário excluído com sucesso!");
    setSeverity("success");
    setOpen(true);

    setQuestionnaire("");
    setQuestionnaireID("");
    setRefresh(true);
  };

  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <Card>
        <AlertCustom
          setOpen={setOpen}
          open={open}
          message={message}
          severity={severity}
        />

        <DeleteQuestionnaireDialog
          open={openDialog}
          setOpen={setOpenDialog}
          deleteFunction={handleDeleteQuestionnaire}
          id={questionnaireId}
        />

        <OpenBackdrop open={openBackDrop} />
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <DropDownMenu
                itemSelected={questionnaireId}
                itemSelector={setQuestionnaireID}
                data={questionnaires}
                filterName={"Questionário"}
              />
            </GridItem>
            {questionnaire && (
              <>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    value={questionnaire?.name}
                    variant="outlined"
                    id="Nome"
                    label="Nome"
                    margin="normal"
                    required
                    disabled
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    value={questionnaire?.reference_year}
                    variant="outlined"
                    id="date"
                    label="Data de referência"
                    margin="normal"
                    required
                    disabled
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    value={questionnaire?.company?.name}
                    variant="outlined"
                    id="Empresa"
                    label="Empresa"
                    margin="normal"
                    required
                    disabled
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    fullWidth
                    value={`https://www.ergovale.com.br/questionario/${questionnaire?.id}`}
                    variant="outlined"
                    id="date"
                    label="Link compartilhável"
                    margin="normal"
                    required
                    disabled
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    <RegularButton
                      color={"success"}
                      type="button"
                      variant="contained"
                      onClick={handleDownloadAtualSheet}
                    >
                      <Icon style={{ marginRight: "4px", marginTop: "-3px" }}>
                        arrow_downward
                      </Icon>
                      Baixar estado atual dos dados
                    </RegularButton>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      marginTop: "10px",
                      marginBottom: "40px",
                    }}
                  >
                    <RegularButton
                      color="danger"
                      type="button"
                      variant="contained"
                      onClick={() => setOpenDialog(true)}
                    >
                      Deletar Questionário
                    </RegularButton>
                  </div>
                </GridItem>

                <GridItem xs={12} sm={12} md={4}>
                  <div
                    style={{
                      marginTop: "20px",
                      textAlign: "left",
                      padding: "20px",
                      height: "100%",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    <h3 style={{ fontWeight: "bold" }}>Instruções:</h3>
                    <ol>
                      <li>
                        Baixe a planilha modelo clicando em "Baixar modelo".
                      </li>
                      <li>Não altere nenhuma coluna da planilha baixada.</li>
                      <li>
                        Preencha todos os campos requisitados na planilha.
                      </li>
                      <li>
                        Após preencher, adicione a planilha no local indicado e
                        depois clique em "Concluir carga".
                      </li>
                      <li>
                        Aguarde alguns minutos até que a carga seja concluída.
                      </li>
                    </ol>
                    <div
                      style={{
                        marginTop: "40px",
                        textAlign: "center",
                      }}
                    >
                      <RegularButton
                        color={"success"}
                        type="button"
                        variant="contained"
                        onClick={downloadSheet}
                      >
                        <Icon style={{ marginRight: "4px", marginTop: "-3px" }}>
                          arrow_downward
                        </Icon>
                        Baixar modelo
                      </RegularButton>
                    </div>
                  </div>
                </GridItem>

                <GridItem xs={12} sm={12} md={8}>
                  <form onSubmit={onSubmit}>
                    <div
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        maxWidth: "400px",
                        flexDirection: "column",
                      }}
                    >
                      <CensoSheet
                        setOpenBackDrop={setOpenBackDrop}
                        file={file}
                        setFile={setFile}
                      />
                      <div
                        style={{
                          marginTop: "20px",
                          textAlign: "center",
                        }}
                      >
                        <RegularButton
                          disabled={!file || isReader}
                          color={"success"}
                          type="submit"
                          variant="contained"
                        >
                          <Icon
                            style={{ marginRight: "4px", marginTop: "-3px" }}
                          >
                            arrow_upward
                          </Icon>
                          Concluir carga
                        </RegularButton>
                      </div>
                    </div>
                  </form>
                </GridItem>
              </>
            )}
          </GridContainer>
        </CardBody>
      </Card>
      <Card>
        <CustomCardHeader
          title={"Criar Novo Questionário"}
          checked={chechedQuestionnaire}
          handleChecked={handleCheckedQuestionnaire}
        />
        {chechedQuestionnaire && (
          <CreateCenso
            setOpen={setOpen}
            setMessage={setMessage}
            setSeverity={setSeverity}
            setCheckedCreateCenso={setCheckedQuestionnaire}
            setRefresh={setRefresh}
            isReader={isReader}
          />
        )}
      </Card>
    </>
  );
}
